import cn from 'classnames';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment, MouseEventHandler, useState } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import useToggles from 'src/services/toggles/useToggles';
import { getProductTileImage } from 'src/utils/product/getProductTileImage';
import getProductURL from 'src/utils/product/getProductURL';
import withTinting from '../../../HOCs/withTinting/withTinting';
import { useProductBadges } from '../../../hooks/product/useProductBadges';
import getAmountSuffix from '../../../utils/product/getAmountSuffix';
import AddButton from '../AddButton/AddButton';
import DiscountDisclaimer from '../DiscountDisclaimer/DiscountDisclaimer';
import Impressionable from '../Impressionable/Impressionable';
import Price from '../Price/Price';
import WholesalePrices from '../WholesalePrices/WholesalePrices';
import { styles } from './ProductTile.styles';
import { useProductTileAvailability } from './hooks/useProductTileAvailability';
import { useProductTileLabels } from './hooks/useProductTileLabels';
import { useProductTilePrices } from './hooks/useProductTilePrices';
import { useProductTileTinting } from './hooks/useProductTileTinting';
import { ProductTileWithTintingProps } from './types';

const FavoriteButton = dynamic(
  () => import('./FavoriteButton/FavoriteButton'),
  { ssr: false },
);

const QuantityBox = dynamic(
  () => import('../QuantityBox/QuantityBox'),
  { ssr: false },
);

const ReplacementButton = dynamic(
  () => import('../ReplacementButton/ReplacementButton'),
  { ssr: false },
);

const Badges = dynamic(
  () => import('../Badges/Badges'),
  { ssr: true },
);

const ProductTile = (props: ProductTileWithTintingProps) => {
  const {
    locale,
    setTintingMode,
    product,
    isFavorite,
    isTintingMode,
    isInSlider,
    productPlace,
    itemIsAdded,
    amount,
    onChangeAmount,
    onFavoriteClick,
    isReplacement,
    onReplace,
    canBeAdded,
    onAddToCart,
    onClick: onLinkClick,
    deliveryType,
    notClickable,
  } = props;

  const localize = useLocalization();
  const [isVisible, setIsVisible] = useState(false);
  const { BUNDLES_AVAILABLE } = useToggles();
  const [tintingModeIsLocal, setTintingModeIsLocal] = useState(true);
  const [renderIsInitialWithAutofocus, setRenderIsInitialWithAutofocus] = useState(false);

  const {
    availabilityWarning,
    isAvailable,
  } = useProductTileAvailability({ product, deliveryType });
  const badges = useProductBadges(product);
  const prices = useProductTilePrices({ product, isAvailable });
  const { title, weightLabel, bundleLabel } = useProductTileLabels(product);

  const handleAddToCart = () => {
    setRenderIsInitialWithAutofocus(true);
    onAddToCart();
  };

  const handleLinkMouseDown: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const controlRenderer = () => {
    if (isReplacement) {
      return (
        <ReplacementButton onReplace={onReplace} />
      );
    }

    if (itemIsAdded) {
      return (
        <QuantityBox
          mobileExtend
          onChangeAmount={onChangeAmount}
          amount={amount}
          unit={product.unit}
          quantity={product.quantity}
          suffix={getAmountSuffix(product.unit, product.bundle, localize, BUNDLES_AVAILABLE)}
          onSetLocalTintingMode={setTintingModeIsLocal}
          place={productPlace}
          initialRenderWithAutofocus={renderIsInitialWithAutofocus}
        />
      );
    }

    return (
      <AddButton onClick={handleAddToCart} disabled={!canBeAdded} />
    );
  };

  const isUnavailable = product.in_stock && !isAvailable;
  const productUrl = getProductURL(locale, product.ean, product.slug);

  useProductTileTinting({
    setTintingMode,
    ean: product.ean,
    amount,
    itemIsAdded,
  });

  return (
    <Fragment>
      <Impressionable onChange={setIsVisible}>
        <Link
          href={productUrl}
          title={title}
          onClick={onLinkClick}
          onMouseDown={handleLinkMouseDown}
          data-testid='product-tile'
          data-productkey={product.ean} // E-sputnik data-attribute
          data-status={isVisible ? 'visible' : 'invisible'}
          data-marker='Product Tile'
          className={cn('ProductTileLink', {
            ProductTileLink_notClickable: notClickable || product.is_uber_item,
          })}
        >
          <span
            className={cn(
              'ProductTile', {
              'ProductTile_slider': isInSlider,
              /* we use different classes for the same style due
              to the specifics of implementing e2e tests.
              Don't merge them without e2e refactoring.
              */
              'ProductTile_withOpacity': !product.in_stock || isUnavailable,
              'ProductTile_tintingMode': (isTintingMode && tintingModeIsLocal),
            })}
            data-testid='product_tile_inner'
          >
            <div
              className={cn(
                'ProductTile__imageContainer', {
                'ProductTile__image_unavailable': isUnavailable,
              })}
              data-testid='product-tile__image'
            >
              <Image
                src={getProductTileImage(product.img)}
                alt={title}
                width={150}
                height={150}
              />
              {product.in_stock && isAvailable && (
                <div
                  className={cn(
                    'ProductTile__footer', {
                    'ProductTile__footer_active': itemIsAdded && !isReplacement,
                  })}
                  data-testid='product-tile__footer'
                >
                  <div className='ProductTile__quantityBox'>{controlRenderer()}</div>
                </div>
              )}
            </div>

            <div className='ProductTile__badges'>
              <Badges
                {...badges}
                straightLeft
              />
            </div>

            <div className='ProductTile__favouritesControl'>
              <FavoriteButton
                onFavoriteClick={onFavoriteClick}
                isChecked={isFavorite}
                tooltipText={localize('product_tile.favorites.manage_button')}
                size='big'
              />
            </div>
            <div className='ProductTile__details'>
              <div className="ProductTile__prices" data-marker='Price'>

                {availabilityWarning && (
                  <div className='ProductTile__unavailable' data-marker='Availability'>
                    <span>{availabilityWarning}</span>
                  </div>
                )}

                {!!prices.wholesale && (
                  <div className='ProductTile__priceWholesales' data-testid='wholesale' >
                    <WholesalePrices
                      language={locale}
                      {...prices.wholesale}
                      isSmall
                    />
                  </div>
                )}

                {!!prices.old && (
                  <div data-marker='Old Price' className='ProductTile__oldPrice'>
                    <Price
                      {...prices.old}
                      variant="body"
                      isMinor
                    />
                  </div>
                )}

                {!!prices.main && (
                  <div data-marker='Discounted Price'>
                    <Price
                      {...prices.main}
                      variant="caption"
                    />

                    {product.discount?.due_date &&
                      <DiscountDisclaimer
                        dueDate={product.discount.due_date}
                        variant="product_tile"
                        place="productTile"
                      />}
                  </div>
                )}

              </div>

              {title && (
                <div className='ProductTile__titleWrapper'>
                  <span
                    className='ProductTile__title'
                    data-testid='product_tile_title'
                  >
                    {title}
                  </span>
                  {(weightLabel || bundleLabel) && (
                    <div
                      className='ProductTile__weight'
                      data-testid='productTileWeight'
                      data-marker='Weight'
                    >
                      {weightLabel}
                      {bundleLabel}
                    </div>
                  )}
                </div>
              )}
            </div>
          </span>
        </Link>
      </Impressionable>

      <style jsx>{styles}</style>
    </Fragment >
  );
};

export default withTinting(ProductTile);
